import React from "react";
import { BASE_API_GATEWAY } from "../../../../../../../../utils/constants/service";
import { getLangPrefix } from "../../../../../../../../helpers/helpers";
import ImgBlob from "../../../../../../../../modules/home/container/components/imgBlob";
import LinkFullUrl from "../../../../../../../../shared/linkFullUrl/linkFullUrl";

interface Props {
    menuItem?: any,
    handleClick: any
}

export default class Common extends React.Component<Props> {

    findingCanonicalurl = (id) => {
        const found = this.props.menuItem.children.find(item => item.id === id);
        return found && found.canonicalUrl ? found.canonicalUrl : ""
    };

    render() {
        const { menuItem } = this.props;
        let menuItemVisuals = (menuItem && menuItem.children) ? menuItem.children.filter((item) => item.visuals && item.visuals[0] && item.visuals[0].type === "MENU") : [];
        const menuItemTexts = (menuItem && menuItem.children) ? menuItem.children.filter((item) => !item.visuals || (item.visuals[0] && item.visuals[0].type === "CATALOGUE")) : [];
        const hasChildren = (menuItemTexts && menuItemTexts.length) ? menuItemTexts.filter((item) => item.children) : [];
        const LANGUAGE_PREFIX = getLangPrefix();
        if(menuItemTexts.length){
            menuItemTexts.map(item => {
                if(item.children && item.children.length) {
                    const visuals = item.children.filter(item => item.visuals && item.visuals[0] && item.visuals[0].type === "MENU")
                    menuItemVisuals = [...menuItemVisuals, ...visuals];
                }
            })
        }

        return (
            <div className="linge-de-lit">
                {menuItemTexts.length ?
                    !hasChildren.length ?
                        <div className="decoration">
                            <ul className="homewear-list">
                                {
                                    menuItemTexts.map(item => <li className="nav-link" key={item.menuId}><LinkFullUrl
                                        to={`${LANGUAGE_PREFIX}${item.canonicalUrl}`} onClick={this.props.handleClick} >{item && item.label}</LinkFullUrl>
                                    </li>)
                                }
                            </ul>
                        </div>
                        :
                        menuItemTexts.length && menuItemTexts.map((item, i) => (
                            <div className={`linge-de-lit-item ${i > 2 && menuItemVisuals.length ? "orderLink" : ""}`} key={item.menuId}>
                                {
                                    <div>
                                        <h4 className="linge-de-lit-item-title">
                                            {item.label}
                                        </h4>
                                        {item.children && item.children.length &&
                                            <ul>
                                                {
                                                    item.children.map(el => el ?
                                                        <li key={el.menuId} className="nav-link">
                                                            <LinkFullUrl to={`${LANGUAGE_PREFIX}${el.canonicalUrl}`} onClick={this.props.handleClick} >{el && el.label}</LinkFullUrl>
                                                        </li> : "")
                                                }
                                            </ul>
                                        }
                                    </div>
                                }
                            </div>
                        )) : ""
                }
                {
                    menuItemVisuals.length ?
                        <div className="linge-de-lit-content">
                            {
                                menuItemVisuals.map(item => (
                                    <LinkFullUrl
                                        to={item.visuals[0].menuHref ? `${LANGUAGE_PREFIX}${item.visuals[0].menuHref}` : `${LANGUAGE_PREFIX}${this.findingCanonicalurl(item.id)}`}
                                        onClick={this.props.handleClick} className="linge-de-lit-item" key={item.menuId}
                                    >
                                        <div className="linge-de-lit-item-img">
                                            <ImgBlob src={`${BASE_API_GATEWAY}${item && item.visuals[0].photo}`}
                                                alt="luxury img" />
                                            <div className="linge-de-lit-item-img-content">
                                                <h4 className="linge-de-lit-item-title">
                                                    {item.label}
                                                </h4>
                                                <p className="jalla-link-btn">
                                                    {item.visuals[0].name}
                                                </p>
                                            </div>
                                        </div>
                                    </LinkFullUrl>
                                ))
                            }
                        </div>
                        :
                        ""
                }
            </div>
        );
    }
}
