import {HomeTypes, HomeActionCreators} from "./actions";
import {takeLatest, put, call} from "redux-saga/effects";
import axios from 'axios';
import {BANNER_TEXT,INSTAGRAM_DATA_URL ,CONTENT_URL, RECOMMENDED_PRODUCTS_URL} from "../../../utils/constants/service";
import {redirectPage, getApiPrefix, decryptCryptr} from "../../../helpers/helpers"
import {BRAND_ID, COUNTRY, DEFAULT_CURRENCY} from "../../../utils/constants/variables";


export function* getHomeData(params) {
    try {
        const headers = {headers: {language: getApiPrefix()}};
        const data = yield call(() => {
            return axios.get(`${CONTENT_URL}${params.id}`, headers)
                .then(res => res.data)
        });

        yield put(HomeActionCreators.homeSuccess(data));
    } catch (e) {
        yield put(HomeActionCreators.homeFailure(e.message));
        if(e.response.status === 503 && params.id === 69) {
            redirectPage()
        }
    }
}

export function* getRecommendedProductsData() {
    try {
        const browserId = decryptCryptr("browserId");
        const data = yield call(() => {
            return axios.get(`${RECOMMENDED_PRODUCTS_URL}?browserId=${browserId}&country=${COUNTRY}&brandId=${BRAND_ID}&language=${getApiPrefix()}&currency=${DEFAULT_CURRENCY}`)
                .then(res => res.data)
        });
        yield put(HomeActionCreators.recommendedProductsSuccess(data));
    } catch (e) {
        yield put(HomeActionCreators.recommendedProductsFailure(e.message));
    }
}

export function* getInstagramData(params) {
    try {
        const headers = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            }
        };
        const data = yield call(() => {
            return axios.get(`${INSTAGRAM_DATA_URL}${BRAND_ID}?count=${params.count}`, headers)
                .then(res => res.data)
        });
        yield put(HomeActionCreators.homeInstagramSuccess(data));
    } catch (e) {
        yield put(HomeActionCreators.homeInstagramFailure(e.message));
    }
}

export function* getBannersData() {
    try {
        const data = yield call(() => {

            return axios.get(`${BANNER_TEXT}/${BRAND_ID}/label-languages?language=${getApiPrefix()}`)
                .then(res => res.data)
        });

        yield put(HomeActionCreators.bannerSuccess(data));
    } catch (e) {

        yield put(HomeActionCreators.bannerError(e.message));

    }
}

export function* homeSaga() {
    yield takeLatest(HomeTypes.HOME_START, getHomeData);
    yield takeLatest(HomeTypes.HOME_INSTAGRAM_START, getInstagramData);
    yield takeLatest(HomeTypes.RECOMMENDED_PRODUCTS_START, getRecommendedProductsData);
    yield takeLatest(HomeTypes.BANNER_START, getBannersData);

}

export default homeSaga;
