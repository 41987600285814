import React from 'react';
import { connect } from 'react-redux';
import i18next from "i18next";

import { getMenu, getStores } from "../main/header/redux/selectors";
import { MenuActionCreators } from "../main/header/redux/actions";
import {
    findNodeByProperty,
    getCatalogueParentMenuId,
    getDataBySectionAndWidget,
    getLangPrefix, stripHTMLreg
} from "../../helpers/helpers";
import { getProducts, getSelectedMenuId } from "../../modules/products/redux/selectors";
import { IHomeState } from "../../modules/home/redux/reducers";
import { getError, getHome, getInstagramData } from "../../modules/home/redux/selectors";
import { HomeActionCreators } from "../../modules/home/redux/actions";
import StyledCurrentPage from './styledCurrentPage';
import CommonCurrentPage from './commonCurrentPage';
import { LocationSearchActionCreators } from '../../modules/locationSearchInput/redux/actions';
import StoreList from '../../modules/storesList/storesList';

interface Props extends IHomeState {
    menuList: any,
    products: any,
    selectedMenuId: any,
    text?: any,
    data: any,
    error: any,
    showDescription?: boolean,
    showPageMetaData?: boolean,
    productData?: any,
    selectedParent?: any,
    postData?: any,
    stores: any[]
}
interface State {
    selectedMenuItem: any,
    styled: boolean,
    productData: any,
    showPageMetaData: boolean
}
let navTabs: any = [];
class CurrentPage extends React.Component<Props & typeof MenuActionCreators, typeof HomeActionCreators> {
    state: State = {
        selectedMenuItem: {},
        styled: false,
        productData: this.props.productData ? this.props.productData : {},
        showPageMetaData: typeof this.props.showPageMetaData === "undefined" ? true : this.props.showPageMetaData
    };

    componentDidMount() {
        if (this.props.productData) {
            this.setState({ productData: this.props.productData })
        }
        if (Object.entries(this.props.menuList).length === 0) {
            this.props.dispatch(MenuActionCreators.menuStart())
        }
        if (this.props.selectedItem) {
            this.getSelectedMenuAndTabs(this.props.selectedItem.menuId, this.props.selectedParent);
        }
        if (this.props.selectedItem && this.props.selectedItem.altUrl1 === "shops") {
            this.props.dispatch(MenuActionCreators.getStoresStart())
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.postData && prevProps.postData && this.props.postData.length && !prevProps.postData.length) {
            this.getSelectedMenuAndTabs(this.props.selectedItem.menuId)
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedMenuId && nextProps.selectedMenuId !== this.props.selectedMenuId) {
            this.getSelectedMenuAndTabs(nextProps.selectedMenuId);
        } else if (nextProps.selectedItem && nextProps.selectedItem.menuId) {

            if (nextProps.productData !== this.props.productData) {
                this.setState({ productData: nextProps.productData }, () => this.getSelectedMenuAndTabs(nextProps.selectedItem.menuId));
            } else if (nextProps.selectedItem !== this.props.selectedItem || nextProps.selectedParent !== this.props.selectedParent) {
                this.getSelectedMenuAndTabs(nextProps.selectedItem.menuId, nextProps.selectedParent);
            }

        }
        if (nextProps.selectedParent !== this.props.selectedParent) {
            this.getSelectedMenuAndTabs(this.props.selectedItem.menuId, nextProps.selectedParent);
        }

    }

    getSelectedMenuAndTabs = (selectedMenuId, selectedParent: any = "") => {
        const menu = this.props.menuList.find(item => item.menuId === selectedMenuId && item.menuType.toUpperCase() === "CATALOGUE");
        let res = this.props.menuList;
        let styled = false;
        let { selectedMenuItem } = this.state;
        navTabs = [
            {
                name: i18next.t("home.page"),
                url: "/"
            }
        ];
        const newSelectedMenuId = getCatalogueParentMenuId(menu, this.props.menuList, "styles_et_tendances") || selectedMenuId;

        while (res) {
            res = findNodeByProperty(res, newSelectedMenuId, "menuId");
            if (res) {
                navTabs.push({ name: res.label, url: res.canonicalUrl ? getLangPrefix() + res.canonicalUrl : "" });
                if (res.children) {
                    res = res.children
                } else {
                    selectedMenuItem = res;
                    res = false;
                    if (!Object.keys(this.state.productData).length && selectedMenuItem.gridTypeId === 5) {
                        this.props.dispatch(HomeActionCreators.homeStart(selectedMenuItem.gridId));
                        styled = true;
                    }
                }
            }
        }

        if (Object.keys(this.state.productData).length) {
            navTabs.push({ name: this.state.productData.name, url: navTabs[navTabs.length - 1].url + "/" + this.state.productData.canonicalUrl, productUrl: true });
        }
        if (selectedParent && selectedParent.length) {
            selectedParent.map(item => navTabs.splice(1, 0, { name: item.label, url: getLangPrefix() + item.canonicalUrl }))
        }

        if (menu && menu.menuType.toUpperCase() === "CATALOGUE") {
            navTabs.push({ name: "Catalogue", url: "" });
        }
        if (this.props.postData && this.props.postData.length) {
            const postdata = getDataBySectionAndWidget(this.props.postData[0].section, 1, this.props.postData, "TEXT", "Title").value.replace(stripHTMLreg, "");
            navTabs.push({ name: postdata, url: "" });

        }

        this.setState({ selectedMenuItem, styled })
    };

    setStores = (stores) => {
        this.props.dispatch(LocationSearchActionCreators.locationSearchSuccess(stores));
    };

    render() {
        const { selectedMenuItem, styled, productData, showPageMetaData } = this.state;
        const { menuList, data, selectedItem, showDescription = true, selectedParent, postData, stores } = this.props;
        let connectItem;
        if (
            menuList &&
            menuList.length &&
            this.props.text === "connect"
        ) {
            connectItem = menuList.find(item => this.props.text === item.key)
        }

        if (!Object.entries(selectedMenuItem).length && selectedItem) {
            connectItem = this.props.selectedItem
        }

        return (
            <>
                {styled ?
                    <StyledCurrentPage
                        navTabs={navTabs}
                        selectedMenuItem={selectedMenuItem}
                        getData={getDataBySectionAndWidget}
                        data={data}
                        productData={productData}
                        showPageMetaData={showPageMetaData}
                    /> :
                    <CommonCurrentPage
                        navTabs={navTabs}
                        selectedMenuItem={selectedMenuItem}
                        connectItem={connectItem}
                        showDescription={showDescription}
                        productData={productData}
                        postData={postData}
                        showPageMetaData={showPageMetaData}
                    />
                }
                {selectedMenuItem.altUrl1 === "shops" && <StoreList stores={stores} setStores={this.setStores} />}
            </>
        )
    }
}

const mapStateToProps = (state: any): Props => ({
    data: getHome(state),
    error: getError(state),
    products: getProducts(state),
    menuList: getMenu(state),
    selectedMenuId: getSelectedMenuId(state),
    stores: getStores(state),
    instagramData: getInstagramData(state)
});



export default connect(mapStateToProps)(CurrentPage);
