import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { withTranslation, WithTranslation } from "react-i18next";
import { loadReCaptcha } from 'react-recaptcha-google';
import i18next from 'i18next';

import './app.scss';
import AppRoutes from './routes';
import ErrorBoundary from './shared/error/error-boundary';
import ScrollToTop from '../src/shared/scrollToTop'
import Gtag from "react-gtm-module";
// import Gtag from "react-ga4";
import { appendButton, getLang, isCookieAllowed } from "./helpers/helpers";
import { BRAND, GTM_TAG, REDONNER_INTEGRATION } from "./utils/constants/variables";

declare global {
    interface Window {
        recaptchaOptions: any;
    }
}

interface IProps extends WithTranslation {
    search?: string,
    loading?: boolean,
    t: i18next.TFunction
}

class App extends Component<IProps & WithTranslation> {

    componentDidMount() {
        // const GA4 = 'UA-25296964-3';
        const GA4 = GTM_TAG || '';

        const tagManagerArgs = {
            gtmId: GA4
        }
        // isCookieAllowed() && Gtag.initialize(tagManagerArgs);
        Gtag.initialize(tagManagerArgs);
        loadReCaptcha();
        window.recaptchaOptions = {
            lang: 'fr',
            // useRecaptchaNet: true,
            removeOnUnmount: false,
        };

        // this.appendRedonner();
    }

    appendRedonner = () => {
        if (!document.getElementById('redonnerScript') && REDONNER_INTEGRATION) {
            const s = document.createElement('script');
            s.id = 'redonnerScript';
            s.type = 'text/javascript';
            s.async = true;
            s.setAttribute('crossorigin', 'anonymous');
            s.src = REDONNER_INTEGRATION;
            const x = document.getElementsByTagName('script')[0];
            x && x.parentNode && x.parentNode.insertBefore(s, x);
            s.onload = () => {
                appendButton()
            }
        }
    }

    render() {
        return (
            <Router>
                <div className="app-container">
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{BRAND}</title>
                        <html lang={getLang()} />
                    </Helmet>
                    <ErrorBoundary>
                        <ScrollToTop />
                        <AppRoutes />
                    </ErrorBoundary>
                </div>
            </Router>
        );
    }
}

export default withTranslation('common')(App);
