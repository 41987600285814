import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Checkbox from '../../../../modules/common/chckboxList/checkboxList';
import { SubscribeActionCreators } from "../redux/actions";
import { getSuccess, getError, getStatus } from "../redux/selectors";
import { Icon } from "semantic-ui-react";
import i18next from "i18next";
import footer from '../../../../assets/img/footerImg.png';
import CAccordion from "./components/accordion";
import FooterItemMenu from "./components/footerItemMenu";
import Search from "./components/search";
import About from "./components/about";
import { Link } from "react-router-dom";
import { BRAND_ID } from './../../../../utils/constants/variables';

//styles
import "./footer.scss";
import LinkFullUrl from "../../../../shared/linkFullUrl/linkFullUrl"
import { getRouteByAltUrl1, getRouteByMenuKey } from '../../../../helpers/helpers';
import { getBrandDetail, getMenu, getMenuItem, getUser } from "../../header/redux/selectors";
import ResponseMessage from "../../../../modules/responseMessage/container";
import PopUp from "../../../../modules/common/ContentpopUp/popUp";
import { getHome } from "../../../../modules/home/redux/selectors";
import { HomeActionCreators } from '../../../../modules/home/redux/actions';
import MenuKeywordWrapper from '../../../../modules/home/container/components/MenuKeyWordWrapper';
import { PRIVATE_SALE_POPUP } from '../../../../utils/constants/variables';
import { getMesInformation, } from '../../../../modules/monEspace/container/components/mesInformations/redux/selectors';
interface Props {
    subscribe: any,
    error: any,
    menuList: any,
    status: string,
    data: any,
    brandDetails: any
    menuItem: any
    webClient: any

}

interface State {
    email: string,
    accept: boolean,
    error: boolean,
    subscribed: boolean,
    visibleMessage: boolean,
    footerMenu: object[],
    subMenu: object[],
}


class Footer extends Component<Props & typeof SubscribeActionCreators, State> {
    state: State = {
        email: '',
        accept: false,
        error: false,
        subscribed: false,
        visibleMessage: true,
        footerMenu: [],
        subMenu: []
    };

    componentDidUpdate(prevProps, prevState): void {
        if (this.props.status && !prevProps.status) {
            if (this.props.status.toUpperCase() === "SUCCESS") {
                this.setState({ subscribed: true });
            }
            setTimeout(() => {
                this.setState({ visibleMessage: false, email: "", accept: false })
            }, 5000)
        }

        if (!prevProps.menuList.length && this.props.menuList.length) {
            this.setState({
                footerMenu: this.getSortedFooterMenu(/^footer_/),
                subMenu: this.getSortedFooterMenu(/^subfooter_/),
            })
        }
    };

    getSortedFooterMenu = (regex) => {
        const data = this.props.menuList
            .filter(
                el => el.key ? el.key.includes("footer_") : false
            )
        return data.filter(el => regex.test(el.key)).sort((a, b) => a.key.split('_')[1] - b.key.split('_')[1])
    }

    getPopData = () => {
        console.log(this.props.data, 'props datatatat')
        return Array.isArray(this.props.data) ? this.props.data.filter(item => item.type === "POP") : []
    };
    isOpenPopup = () => {
        if (PRIVATE_SALE_POPUP === "0") {
            return false
        } else if (PRIVATE_SALE_POPUP === "1") {
            const noPop = localStorage.getItem('noPop')
            if ((this.props.webClient && this.props.webClient.newsOptinDate) || (noPop && noPop === 'saved')) {
                return false
            } else {
                return true
            }
        }

    }
    handleChange = (e) => {
        this.setState({ accept: e.target.checked, error: false })
    };
    handleSubscribe = (e) => {
        const { email, accept } = this.state;
        const body = {
            email,
            "brandId": BRAND_ID
        }
        if (accept) {
            this.props.subscribeStart(body);
            this.setState({ error: false })
        } else {
            this.setState({ error: true })
        }
        this.setState({ visibleMessage: true });
        e.preventDefault();
    };
    handleToUp = () => {
        const { detect } = require('detect-browser');
        const browser = detect();
        if (browser.name === "firefox") {
            setTimeout(() => window.scrollTo(0, 0), 0);
            const key: any = { 'key': "Home", 'keyCode': 36, 'which': 36 };
            const evt = new KeyboardEvent('keypress', key);
            setTimeout(() => document.dispatchEvent(evt), 0);
        } else {
        }
        window.scrollTo(0, 0)
    };
    hendleChangeStateEmail = (e) => {
        this.setState({ email: e.target.value })
    };

    render(): React.ReactNode {
        const { email, error, visibleMessage, accept, footerMenu, subMenu } = this.state;
        const { menuList, status, promotion, subscribe, menuItem } = this.props;
        let statusWitherrorResult = subscribe.result !== "ERROR" && status === "success" ? "success" : "failed"

        return (
            <footer className="footer">
                {
                    this.isOpenPopup() ? <PopUp promotion={promotion} menuList={this.props.menuList} status={status} /> : ""
                }
                <MenuKeywordWrapper menuItem={menuItem} />
                <Search />
                <About />
                <CAccordion
                    footerImg={footer}
                    menuList={menuList}
                    footerMenu={footerMenu}
                    subscribeStart={this.props.subscribeStart}
                    status={status}
                />

                <div className="footer-content">
                    <div className="home-container">
                        <div className="d-start">
                            <div className="services d-start">
                                <div className="services-img">
                                    <div className="services-img-text">
                                        <h4>{i18next.t('footer.contact.1')}</h4>
                                        <p>{i18next.t('footer.contact.2')}<br /></p>
                                        <div className="jalla-link-btn">
                                            <LinkFullUrl onClick={this.handleToUp} to={getRouteByMenuKey(menuList, "contact").route}>{i18next.t('footer.contact.3')} </LinkFullUrl>
                                        </div>
                                    </div>
                                </div>

                                <ul className="services-links" >
                                    <li>{i18next.t('footer.services.1')}</li>
                                    <FooterItemMenu
                                        data={footerMenu}
                                        handleToUp={this.handleToUp}
                                    />
                                </ul>
                            </div>

                            <div className="contact">

                                <h4>{i18next.t('footer.news.1')}</h4>
                                <form onSubmit={this.handleSubscribe}>
                                    <label htmlFor="email"><span className="Span">{i18next.t('footer.news.2')}</span> </label>
                                    <input
                                        id="email"
                                        type="email"
                                        placeholder={i18next.t('footer.news.2')}
                                        className="email"
                                        value={email}
                                        onChange={this.hendleChangeStateEmail}
                                    />
                                    <div className="contact-check">
                                        <Checkbox
                                            text={i18next.t('footer.news.3')}
                                            data={
                                                <Link
                                                    to={getRouteByAltUrl1(menuList, "personal_data")}
                                                >{i18next.t('footer.news.7')}</Link>
                                            }
                                            name="connection_2"
                                            checked={accept}
                                            handleChange={this.handleChange}
                                            elemStyle={error ? "error" : ""}
                                        />
                                    </div>
                                    <button disabled={this.state.subscribed} className="subscribe-btn" type="submit">{i18next.t('footer.news.4')}</button>
                                </form>

                                {visibleMessage && status && accept ? <ResponseMessage status={status} errorText={i18next.t('footer.news.6')} successText={`${this.state.email} ${i18next.t('footer.news.5')}`} /> : ""}

                                <hr />

                                <h4 className="title">{i18next.t('footer.social.1')}</h4>
                                <div className="social">
                                    <a className="social-item" href="https://www.facebook.com/BassettiOfficial" target="_blank"><Icon name="facebook f" /></a>
                                    <a className="social-item" href="https://www.instagram.com/bassetti_official/" target="_blank"><Icon name="instagram" /></a>
                                    <a className="social-item" href="https://open.spotify.com/user/31cujkwetiq34uvyxuotcppjn26a?si=d66fb997592b445d" target="_blank"><Icon name="spotify" /></a>
                                    <a className="social-item" href="https://www.pinterest.com/Bassetti1830/" target="_blank"><Icon name="pinterest" /></a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="copy-right">
                    <div className="container d-between">
                        <div>{i18next.t('footer.mini.0')}</div>
                        <ul className="copy-right">
                            <FooterItemMenu
                                data={subMenu}
                                handleToUp={this.handleToUp}
                            />
                        </ul>
                    </div>
                </div>
            </footer>
        );
    }
}


const mapStateToProps = (subscribeState: any): Props => ({
    data: getHome(subscribeState),
    subscribe: getSuccess(subscribeState),
    error: getError(subscribeState),
    status: getStatus(subscribeState),
    menuList: getMenu(subscribeState),
    brandDetails: getBrandDetail(subscribeState),
    menuItem: getMenuItem(subscribeState),
    webClient: getUser(subscribeState)

});

const mapDispatchToProps = (dispatch): typeof SubscribeActionCreators => {
    return bindActionCreators({ ...SubscribeActionCreators, ...HomeActionCreators }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
