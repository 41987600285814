import React, { Fragment } from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import i18next from "i18next";
import publicIp from 'public-ip';
import ReactGA from 'react-ga4';
import zucchi from '../../../../assets/img/Zucchi.png';
import BassetiLogo from '../../../../assets/img/Bassetti.png';

import BasketPopup from "../../../../modules/basketPopup/container/basketPopup";
import { encrypt, fingerprint, getItemMenu } from "../../../../helpers/helpers";
import { EXPRESS_DELIVERY, EXPRESS_SHIPMENT, GTM_TAG, PAGE_TYPE, COOKIE_TRACKING, LANGUAGE } from "../../../../utils/constants/variables";
import { getCookieContent, getLanguage, getMenuLoading, getMenu, getBrandDetail } from "../redux/selectors";
import PromotionBannerRender from "../../promotionBanner/promotionBannerRender";
import { MenuActionCreators } from '../redux/actions';

import "./header.scss";

interface Props {
    data: any,
    language: string,
    routeList?: any,
    menuIsLoading: boolean,
    menuList: any;
    brandDetail: any;
}

interface State {
    showBasket: boolean,
    showCookiePopup: boolean,
    cookiePopup: boolean,
    content: any,
    showCookieMsg: boolean,
    checkwindowWidthMobile: boolean,
}

class Header extends React.Component<Props & typeof MenuActionCreators> {
    state: State = {
        showBasket: false,
        showCookiePopup: false,
        cookiePopup: false,
        content: null,
        showCookieMsg: true,
        checkwindowWidthMobile: false,
    };

    componentDidMount() {
        if (EXPRESS_DELIVERY === "1") {
            encrypt("express_shipment", EXPRESS_SHIPMENT)
        } else {
            localStorage.removeItem("express_shipment")
        }

        encrypt("browserId", JSON.stringify(fingerprint(this, screen, navigator)));

        if (!localStorage.getItem("ip")) {
            publicIp.v4()
                .then(res => {
                    encrypt("ip", res)
                })
                .catch(err => console.log(err))
        }
        window.addEventListener('resize', (event) => {
            if (window.innerWidth <= 768) {
                this.setState({ checkwindowWidthMobile: true })
            } else {
                if (this.state.checkwindowWidthMobile && window.innerWidth > 768) {
                    this.setState({ checkwindowWidthMobile: false })
                }
            }
        });

        this.props.brandDetailStart();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.menuIsLoading && !this.props.menuIsLoading && this.props.menuList.length) {
            const menuItem = getItemMenu(PAGE_TYPE, "cookie", this.props.menuList)
            if (menuItem && menuItem.gridId) {
                const access = localStorage.getItem("access") ? true : false;
                this.props.cookieContentStart(menuItem.gridId);
                this.setState({ showCookiePopup: access });
            }
        }

        if (!prevProps.data.length && this.props.data.length) {
            this.setState({ content: this.props.data });
            let access = localStorage.getItem("access") ? true : false;
            this.setState({ showCookiePopup: access });
            this.props.navStyles(access);
        }

        if (this.props.brandDetail !== prevProps.brandDetail && LANGUAGE !== this.props.brandDetail.lowerlanguage) {
            i18next.changeLanguage(this.props.brandDetail.lowerlanguage);
        }
        const acceptContainer = document.getElementsByClassName('iubenda-cs-accept-btn iubenda-cs-btn-primary');
        const declineContainer = document.getElementsByClassName('iubenda-cs-reject-btn iubenda-cs-btn-primary');
        const openCookies = document.getElementsByClassName('iubenda-cs-customize-btn');

        if (acceptContainer && acceptContainer[0]) {
            const acceptButton = acceptContainer[0]
            acceptButton.addEventListener('click', () => {
                this.addLocalStorage('cookieApproval');
                // location.reload();
            })
        }

        if (declineContainer && declineContainer[0]) {
            const declineButton = declineContainer[0]
            declineButton.addEventListener('click', () => {
                this.addLocalStorage('cookieRejected')
                location.reload();
            })
        }

        if (openCookies && openCookies[0]) {
            const openCookie = openCookies[0]
            let isApproved = false;
            openCookie.addEventListener('click', (e) => {
                setTimeout(() => {

                    const saveButton = document.getElementById('iubFooterBtn');
                    const acceptAllContainer = document.getElementsByClassName('iub-btn iub-btn-consent iub-btn-accept purposes-btn purposes-btn-accept');
                    const declineAllContainer = document.getElementsByClassName('iub-btn iub-btn-consent iub-btn-reject purposes-btn purposes-btn-reject');

                    if (saveButton) {
                        saveButton.addEventListener('click', () => {
                            this.addLocalStorage(isApproved ? 'cookieApproval' : 'cookieRejected');
                            location.reload();
                        })
                    }
                    if (acceptAllContainer && acceptAllContainer[0]) {
                        const acceptAllButton = acceptAllContainer[0]
                        acceptAllButton.addEventListener('click', () => {
                            isApproved = true;
                        })
                    }
                    if (declineAllContainer && declineAllContainer[0]) {
                        const declineAllButton = declineAllContainer[0]
                        declineAllButton.addEventListener('click', () => {
                            isApproved = false;
                        })
                    }
                }, 100);
            })
        }

    }

    handleBasketToggle = (show) => {
        this.setState({ showBasket: show })
    };

    showCookiePopup = () => {
        this.setState({ cookiePopup: true })
    };

    closeCookiePopup = () => {
        this.setState({ cookiePopup: false })
    };

    addLocalStorage = (value) => {
        if (value === 'cookieApproval' && COOKIE_TRACKING === 1) {
            ReactGA.initialize(GTM_TAG || '');
        }
        if (value === 'cookieRejected') {
            ReactGA.reset()
        }
        localStorage.setItem("access", value);
        this.setState({ cookiePopup: false })
    };

    render(): React.ReactNode {
        const { routeList, menuIsLoading, data } = this.props;
        const { showBasket, showCookiePopup, cookiePopup, content, showCookieMsg } = this.state;
        let access = !!(localStorage.getItem("access"));
        const cookieApproval = !showCookiePopup && !access ? 'cookie-popup' : '';
        const cookiePopupClass = cookiePopup ? 'cookie-popup-show' : '';

        const homeMenu = routeList.length ? routeList[0] : undefined;
        const promotion = homeMenu ? homeMenu.promotion : "";
        const promotionEnd = homeMenu ? homeMenu.promotionEnd : "";

        return (
            <Fragment>
                <div>
                    <div className={`hide-modal ${cookiePopupClass}`}>
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <button className='close-btn' onClick={this.closeCookiePopup}>X</button>
                            </div>
                            {
                                content &&
                                <div className='modal-inner' dangerouslySetInnerHTML={{ __html: content[0].value }}>
                                </div>
                            }
                            <div className='modal-footer'>
                                <button
                                    className='close-btn'
                                    onClick={() => {
                                        location.reload()
                                        this.addLocalStorage('cookieRejected')
                                    }}
                                >
                                    {i18next.t("cookie.4")}
                                </button>
                                <button
                                    className='main-btn'
                                    onClick={() => {
                                        this.addLocalStorage('cookieApproval');
                                        location.reload();
                                    }}
                                >
                                    {i18next.t("cookie.3")}
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* {
                        data && data.length ?
                        <Iubenda id={3431089} type='cookie' styling='black'>
                            <div className={`cookie-popup-hide ${showCookieMsg ? cookieApproval : ""}`}>
                                <div className='gtm-load' onClick={() => {
                                    location.reload()
                                    this.addLocalStorage('cookieRejected')
                                }}>
                                    <p>{i18next.t("cookie.5")}</p>
                                </div>
                                {i18next.t("cookie.1")}
                                <div className='d-flex align-items-center justify-content-center'>
                                    <button onClick={this.showCookiePopup} >{i18next.t("cookie.2")}</button>
                                    <button
                                        className='main-btn main-btn-Jaccepte'
                                        onClick={() => {
                                            this.addLocalStorage('cookieApproval');
                                            location.reload();
                                        }}
                                    >
                                        {i18next.t("cookie.3")}
                                    </button>
                                </div>
                            </div> 
                          </Iubenda>
                            : ""
                    } */}
                    <header className="header">
                        <div className="header-head">
                            <div className="header-head-content ">
                                <ul className="header-head-partniors">
                                    {/*descamps, jalla, zucchi*/}
                                    {/* <li>
                                        <a href={"https://www.descamps.com"} rel="noopener" target="_blank"><img src={descamps} alt="descamps logo"/></a>
                                    </li>
                                    <li>
                                        <a href={"https://www.jalla.com"} rel="noopener" target="_blank"><img src={jalla} alt="jall logo"/></a>
                                    </li>
                                    <li>
                                        <a href={"https://www.descamps.com/tommy-hilfiger"} target="_blank" rel="noopener" className="tommyLogo"><img src={tommy} alt="tommy logo" /></a>
                                    </li> */}
                                    {/*bassetti, zucchi*/}
                                    <li>
                                        <a href={"https://www.bassetti.com/"} target="_blank" rel="noopener"><img src={BassetiLogo} alt="bassetti logo" /></a>
                                    </li>
                                    <li>
                                        <a href={"https://www.zucchi.it/"} target="_blank" rel="noopener"><img src={zucchi} alt="zucchi logo" /></a>
                                    </li>
                                    {/*{this.state.checkwindowWidthMobile ? null :*/}
                                    {/*    */}
                                    {/*}*/}

                                    {/*<li>*/}
                                    {/*    <a href={"https://www.bassetti.de"} target="_blank" rel="noopener" className="tommyLogo"><img src={BassetiDeLogo} alt="tommy logo" /></a>*/}
                                    {/*</li>*/}
                                </ul>
                            </div>
                        </div>

                    </header>
                    {showBasket &&
                        <BasketPopup handleBasketToggle={this.handleBasketToggle} />
                    }

                </div>
                {!menuIsLoading && promotion && (promotion.toUpperCase() === "Y" || promotion.toUpperCase() === "N") ?
                    <PromotionBannerRender promotionEnd={promotionEnd} />
                    : ""
                }
            </Fragment>
        )
    }
}

const mapStateToProps = (state: any): Props => ({
    data: getCookieContent(state),
    language: getLanguage(state),
    menuIsLoading: getMenuLoading(state),
    menuList: getMenu(state),
    brandDetail: getBrandDetail(state)
});

const mapDispatchToProps = (dispatch): typeof MenuActionCreators => {
    return bindActionCreators(MenuActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
