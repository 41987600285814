import React from "react";
import i18next from "i18next";
import ComplectItem from "./complectItem";
import { getCurrency, parseData, replaceComma } from "../../../../helpers/helpers";
import Colorbar from "./colorbar";
import Photo from "../../../common/product/photo";
import { PERSONALIZATION, PRODUCT_MAX_QUANTITY } from "../../../../utils/constants/variables";
import StoreLocator from "../../../location/container/storeLocator";
import AddToCart from "../../../addToCart/container/addToCart";
import { connect } from "react-redux";
import { isAddedToCart } from "../../../addToCart/redux/selectors";
import { bindActionCreators } from "redux";
import { AddToCartActionCreators } from "../../../addToCart/redux/actions";
import RecommendedProducts from "../../../home/container/components/recommendedProducts";
import Price from "../../../common/product/price";
import SocialBtn from "./socialMediaShareButtons";
import { BASE_API_GATEWAY } from "../../../../utils/constants/service";
import { Icon } from "semantic-ui-react";
import FullPageSlider from "../../../fullPageSlider/fullPageSlider";
import HeardIcon from "../../../products/container/heardIcon";
import { ProductActionCreators } from "../../redux/actions";


interface Props {
    handleBasketToggle?: any,
    data?: any,
    handleScrollToDescription?: any,
    addtocartStart?: any,
    addedToCart?: any,
    recommendedProducts?: any,
    parureSrc?: string,
    menuItem?: any,
    handleAddToCartEvent?: any,
    selectColor?: any,
}

interface State {
    reset: boolean,
    quantity: number,
    selectedProductsQuantity: number,
    total: number,
    selectedProducts: any,
    isShownDetails: boolean,
    showColors: boolean,
    productList: any,
    showStoreLocator: boolean,
    showSizeGuide: boolean,
    width: any,
    zIndexPopup: boolean,
    isFullPageSliderVisible: boolean;
}

class Complect extends React.Component<Props & typeof AddToCartActionCreators, State> {
    state: State = {
        reset: false,
        quantity: 0,
        selectedProductsQuantity: 0,
        total: 0,
        isShownDetails: false,
        showColors: true,
        selectedProducts: {},
        productList: this.props.data.compositeData ? this.props.data.compositeData.products : this.props.data.products,
        showStoreLocator: false,
        showSizeGuide: false,
        width: window.innerWidth,
        zIndexPopup: false,
        isFullPageSliderVisible: false,
    };

    componentWillMount(): void {
        window.scrollTo(0, 0)
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() { window.removeEventListener("resize", this.handleResize); }

    handleResize = () => { this.setState({ width: window.innerWidth }) };

    handleZindex = () => this.setState(prevState => { return { ...prevState, zIndexPopup: !prevState.zIndexPopup } });

    handleChangeSelected = (productId, quantity, productReference) => {
        let selectedProducts = this.state.selectedProducts;
        productId = productId.toString();
        if (Object.keys(selectedProducts).includes(productId)) {
            delete selectedProducts[productId];

        }
        else {

            const product = Array.isArray(this.state.productList) ? this.state.productList.find(item => item.productId === parseInt(productId)) : {};
            const price = productReference === "" ? this.getPrice(product) : this.getPrice(product.sizes && product.sizes.filter(elem => elem.productReference == productReference)[0]);
            const size = productReference === "" ? {} : product.sizes && product.sizes.filter(elem => elem.productReference == productReference)[0];
            selectedProducts[productId] = {
                quantity: quantity.toString(),
                size,
                price,
                photo: product.photo,
                name: product.name,
                canonicalUrl: product.canonicalUrl,
                pack: product.pack || [],
                maxQuantity: PRODUCT_MAX_QUANTITY,
                oldPrice: product.discountDetail ? product.startingPrice : "",
                productCategory: product.productCategory
            };
        }
        this.setState({ selectedProducts, reset: false });
        this.calculateTotal();
    };

    handleChangeQuantity = (productId, quantity, sizeIndex) => {
        const { selectedProducts } = this.state;
        if (quantity > 0 && sizeIndex !== "" && !selectedProducts[productId]) {
            this.handleChangeSelected(productId, quantity, sizeIndex);

            this.calculateTotal();
        }

        if (selectedProducts[productId]) {
            if (quantity === 0) {
                this.handleChangeSelected(productId, quantity, sizeIndex);
                this.calculateTotal();

            } else {
                selectedProducts[productId].quantity = quantity;
                // mobile ? "" :  selectedProducts[productId].isActive = quantity == 0 ? false : true;
                this.setState({ selectedProducts });
                this.calculateTotal();
            }
        }

    };

    handleChangeSize = (productId, productReference, quantity) => {
        const { selectedProducts } = this.state;
        const product = Array.isArray(this.state.productList) ? this.state.productList.find(item => item.productId === parseInt(productId)) : {};
        if (productReference !== "") {
            if (quantity > 0 && !selectedProducts[productId]) {
                this.handleChangeSelected(productId, quantity, productReference)

            }
            if (selectedProducts[productId]) {
                const price = productReference === "" ? this.getPrice(product) : this.getPrice(product.sizes && product.sizes.filter(elem => elem.productReference == productReference)[0]);
                selectedProducts[productId].size = productReference === "" ? {} : product.sizes && product.sizes && product.sizes.filter(elem => elem.productReference == productReference)[0];
                selectedProducts[productId].price = price;

            }
            this.setState({ selectedProducts });
            this.calculateTotal();

        } else if (selectedProducts[productId]) {
            this.handleChangeSelected(productId, quantity, productReference)
            this.setState({ selectedProducts });
        }
    };

    getPrice = (data) => {
        return data && data.discountDetail ? data.listPriceAfterDiscount : data.startingPrice;
    };

    calculateTotal() {
        const { selectedProducts } = this.state;
        let total = 0;
        let quantity = 0;
        for (const index of Object.keys(selectedProducts)) {
            quantity += parseInt(selectedProducts[index].quantity);
            total += (selectedProducts[index].quantity * parseFloat(selectedProducts[index].price));
        }
        this.setState({ quantity, total })
    };

    toggleDetails = () => {
        this.setState({ isShownDetails: !this.state.isShownDetails })
    };

    toggleColors = () => {
        this.setState({ showColors: !this.state.showColors })
    };

    handleAddedToCart = () => {
        this.setState({ selectedProducts: {}, total: 0, quantity: 0, reset: true });
        this.props.handleBasketToggle();
    };

    toggleStoreLocator = () => {
        this.setState({ showStoreLocator: !this.state.showStoreLocator });
        this.props.locationSearchDestroyedStart();
    };

    toggleSlider = () => {
        const { isFullPageSliderVisible } = this.state;
        this.setState({ isFullPageSliderVisible: !isFullPageSliderVisible });
    }

    handleClickColor = () => {
        this.props.selectColor(true)
    };

    render() {
        let { data, data: { productType }, recommendedProducts } = this.props;
        const params = {
            slidesPerView: 1,
            // init: false,
            speed: 600,
            autoplay: { delay: 5000 },
            loop: data && data.length,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            wrapperClass: "swiper-wrapper",
            containerClass: "swiper-container",
            setWrapperSize: true,
            resizeReInit: true,
        };
        const {
            quantity,
            total,
            selectedProducts,
            isShownDetails,
            showColors,
            showStoreLocator,
            width,
            zIndexPopup,
            isFullPageSliderVisible
        } = this.state;

        const description: any = data.description ? parseData(data.description) : "";
        let isMobile = width < 920;
        if (data.compositeData) {
            data = data.compositeData;
        }

        return (
            <>
                {data && Object.entries(data).length ?
                    <div className={`pure-complect ${productType === "C" ? "type-c" : ""}`}>

                        <div className={`product-container ${productType === "C" ? "recommendedParnetBox flex-between" : ""}`}>
                            <h4 className="pure-complect-title">{i18next.t('product.parure.1')}</h4>
                            <div className="pure-complect-content">
                                <div className="pure-complect-image-content" style={zIndexPopup ? { zIndex: 10 } : {}}>
                                    <div className="pure-complect-image-box">
                                        <div className="aws-sld.photo-zoom">
                                            <div
                                                className="zoomContent  c-pointer"
                                                onClick={this.toggleSlider}
                                            >
                                                <Icon
                                                    className="c-pointer zoom-icon"
                                                    name='zoom in'
                                                    size='large'
                                                />
                                                <HeardIcon product={data} menuItem={this.props.menuItem} />
                                                <Photo src={data && data.photo}
                                                    discountDetail={data.discountDetail}
                                                    onSpecial={data.onSpecial}
                                                    thumbnail={data && data.thumbnail}
                                                    alt={data && data.metaTitle}
                                                    zoom={false}
                                                    isMobile={isMobile}
                                                    outlet={data.outlet}
                                                    selectedProduct={selectedProducts}
                                                />
                                                {data && data.compositeCanonicalUrlForSingle ? <div className="discount-box">
                                                    <span onClick={() => this.props.handleScrollToDescription()}>{i18next.t('product.parure.18')}</span>
                                                </div> : null}
                                            </div>
                                        </div>
                                        <div className="p-relative">
                                            <h4 onClick={() => this.props.handleScrollToDescription("h4")}>{i18next.t('product.parure.2')}</h4>
                                        </div>
                                    </div>

                                    <div>
                                        <div className="price-count">
                                            <p><span>{quantity}</span> {quantity < 2 ? i18next.t('product.parure.16') : i18next.t('product.parure.4')}</p><span>{replaceComma(total)} {getCurrency(data.currency)}</span>
                                        </div>

                                        <div className="pure-complect-content-button" />
                                        <ul className="pure-complect-product-checked-list">
                                            { // Todo

                                                Object.keys(selectedProducts).map((productId) => (
                                                    <li className="dflex justify-content-between align-items-start"
                                                        key={productId}>
                                                        <span>{selectedProducts[productId].name} - {selectedProducts[productId].size.size} </span>
                                                        <span className="price">{selectedProducts[productId].quantity} x <Price startP={selectedProducts[productId].size.startingPrice} discount={selectedProducts[productId].size.discountDetail} newP={selectedProducts[productId].size.listPriceAfterDiscount} /></span>
                                                    </li>
                                                ))
                                            }
                                        </ul>



                                        <AddToCart
                                            buttonText={i18next.t('product.parure.6')}
                                            data={selectedProducts}
                                            isComplect={true}
                                            handleAddedToCard={this.handleAddedToCart}
                                            isEnable={Object.keys(selectedProducts).length}
                                            toggleStoreLocator={this.toggleStoreLocator}
                                            handleZindex={this.handleZindex}
                                            handleAddToCartEvent={this.props.handleAddToCartEvent}
                                            menuItem={this.props.menuItem}
                                        />
                                        <div className="show-store-locator"
                                            onClick={this.toggleStoreLocator}>{i18next.t('product.product.6')}</div>




                                    </div>

                                </div>

                                <div className="pure-complect-item-about-content">
                                    <div className="pure-complect-item-about">
                                        {productType === "C" &&
                                            <div className="product-info-content product-info--leftBox--content">
                                                <h1 className="pure-complect-item-about-title">{data.name}</h1>
                                                <h5 className="pure-complect-item-about-subtitle">{data.collection}</h5>
                                                {!isShownDetails &&
                                                <>
                                                    <h2 className="pure-complect-item-about-text" >{description.length > 200 ? `${description.substring(0, 200)}...` : description}
                                                        <span onClick={() => this.props.handleScrollToDescription()}>{i18next.t('product.parure.11')}</span>
                                                    </h2>
                                                    {/*<p className="pure-complect-item-about-text">Couleurs disponibles</p>*/}
                                                    {data.colors && data.colors.length > 1 ?
                                                        <div className="product-color">
                                                            <h4 className="product-color-title"
                                                                onClick={this.toggleColors}>{i18next.t('product.product.2')}</h4>
                                                            {showColors &&
                                                            <Colorbar 
                                                                data={data.colors} 
                                                                handleClickColor={this.handleClickColor}
                                                            />
                                                            }
                                                        </div>
                                                        :
                                                        ""
                                                    }
                                                    </>
                                                }

                                            </div>
                                        }

                                    </div>

                                    <div className="complect-item-content">
                                        {
                                            isShownDetails &&

                                            <AddToCart
                                                buttonText={i18next.t('product.parure.6')}
                                                data={selectedProducts}
                                                isComplect={true}
                                                handleAddedToCard={this.handleAddedToCart}
                                                isEnable={quantity}
                                                toggleStoreLocator={this.toggleStoreLocator}
                                                handleZindex={this.handleZindex}
                                                menuItem={this.props.menuItem}
                                            />
                                        }
                                        {data.products &&
                                            data.products.slice(0, 8).map(item => {
                                                return <ComplectItem
                                                    handleChangeSelected={this.handleChangeSelected}
                                                    handleChangeQuantity={this.handleChangeQuantity}
                                                    handleChangeSize={this.handleChangeSize}
                                                    data={item}
                                                    key={item.productId}
                                                    reset={this.state.reset}
                                                    selectedProducts={this.state.selectedProducts}
                                                    handleAddedToCart={this.handleAddedToCart}
                                                    alt={data.metaTitle}
                                                    toggleStoreLocator={this.toggleStoreLocator}
                                                    handleZindex={this.handleZindex}
                                                    menuItem={this.props.menuItem}
                                                />
                                            })}

                                    </div>
                                </div>

                            </div>
                            <div className="product-info--leftBox--sliderContent">
                                {
                                    PERSONALIZATION === "1" && productType === "C" ?
                                        <RecommendedProducts recommendedProducts={recommendedProducts} sliderType="productinfo" /> : null
                                }
                                {
                                    productType === "C" ? <SocialBtn urls={data && BASE_API_GATEWAY + data.photo} /> : ""
                                }

                            </div>
                        </div>
                    </div>

                    : ""
                }
                {showStoreLocator &&
                    <StoreLocator toggleStoreLocator={this.toggleStoreLocator} />
                }
                <FullPageSlider
                    onClose={this.toggleSlider}
                    show={isFullPageSliderVisible}
                    data={data && data.photosX75 && data.photosX75.length && data.photosX75 || []}
                />
            </>
        );
    }
}
const mapStateToProps = (state: any): Props => ({
    addedToCart: isAddedToCart(state),
});

const mapDispatchToProps = (dispatch): any => {
    return bindActionCreators({ ...AddToCartActionCreators, ...ProductActionCreators }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Complect);
