import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { configureStore } from "./store/configure-store";
import { any } from "prop-types";
import { Provider } from "react-redux";
import { I18nextProvider, initReactI18next } from 'react-i18next';
import { BASSETTI_DE, COUNTRY, IPSTACK_KEY } from './utils/constants/variables';
import App from './app';
import ErrorBoundary from "./shared/error/error-boundary";
import detector from "i18next-browser-languagedetector";
import i18next from 'i18next';
import common_it from './utils/translations/it/common.json';
import publicIp from 'public-ip';

import './index.css';
import 'semantic-ui-css/semantic.min.css';


export const store = configureStore();

const start = async () => {
    const rootEl = document.getElementById('root');
    let country = localStorage.getItem("country");

    if (!country) {
        try {
            const res = await publicIp.v4();
            const { country_code, zip } = await fetch(`https://api.ipstack.com/${res}?access_key=${IPSTACK_KEY}`).then((response) => response.json())
            country = country_code;
            localStorage.setItem("country", country_code);
            const city = localStorage.getItem("shippingCity")
            if (!city) localStorage.setItem("shippingCity", zip);
        } catch (error) {
            country = COUNTRY;
            localStorage.setItem("country", COUNTRY);
        }
    }

    if (country === "CH") window.open(BASSETTI_DE, "_self");

    i18next
        .use(detector)
        .use(initReactI18next)
        .init({
            resources: {
                // fr: { common: common_fr },
                // en: {common: common_en},
                it: {common: common_it},
                // de: {common: common_de},
            },
            fallbackLng: 'it',
            ns: ['common'],
            defaultNS: 'common',
            detection: {
                order: ['path'],
                lookupFromPathIndex: 0
            },
            whitelist: ['it'], //['fr', 'it', 'en']
            react: {
                wait: true,
                transEmptyNodeValue: '', // what to return for empty Trans
                transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
                transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
            },
        });
    const render = Component => ReactDOM.render(
        <ErrorBoundary>
            <I18nextProvider i18n={i18next}>
                <Provider store={store}>
                    <div>
                        <Component />
                    </div>
                </Provider>
            </I18nextProvider>
        </ErrorBoundary>,
        rootEl
    );

    render(App);
}


start();

//sagaMiddleware.run(helloSaga)

//const action = type => store.dispatch({type:any})
store.dispatch({type: any});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// if (!process.env.PUBLIC_URL && process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
//     // registration
//     serviceWorker.register();
// }else {
    serviceWorker.unregister();
//}
