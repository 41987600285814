import { MesCommandesTypes, MesCommandesActionCreators } from "./actions";
import { takeLatest, put, call } from "redux-saga/effects";
import { WEB_ORDER, WEB_STORE_SALES } from "../../../../../../utils/constants/service";
import axios from 'axios';

export function* getMesCommandes() {
    try {
        const headers = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            }
        };

        const data = yield call(() => {
            return axios.get(WEB_ORDER, headers)
                .then(res => res.data)
        });

        yield put(MesCommandesActionCreators.mesCommandesSuccess(data));
    } catch (e) {
        yield put(MesCommandesActionCreators.mesCommandesFailure(e.message));
    }
}

export function* getMesTickets() {
    try {
        const headers = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            }
        };

        const { clientOrders = [] } = yield call(() => {
            return axios.get(WEB_STORE_SALES, headers)
                .then(res => res.data)
        });

        yield put(MesCommandesActionCreators.mesTicketsSuccess(clientOrders));
    } catch (e) {
        yield put(MesCommandesActionCreators.mesTicketsFailure(e.message));
    }
}

export function* mesCommandesSaga() {
    yield takeLatest(MesCommandesTypes.MES_COMMANDES_START, getMesCommandes);
    yield takeLatest(MesCommandesTypes.MES_TICKETS_START, getMesTickets);
}

export default mesCommandesSaga;