import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ValidationActionCreators } from "../redux/actions";
import { getBasketData, getLangPrefix } from "../../../helpers/helpers";
import { Redirect } from "react-router-dom"

import { getDiscount } from "../../basket/redux/selectors";
import { OrderActionCreators } from "../../basket/redux/actions";
import { DISCOUNT_CODE } from "../../../utils/constants/variables";

interface Props  {
    discount: any,

}

class ValidationDiscount extends React.Component<Props & typeof ValidationActionCreators> {
    componentDidMount(): void {
        const promoCode = this.props.location && this.props.location.search &&  this.props.location.search.split("=")[1];
        const discountCode = localStorage.getItem(DISCOUNT_CODE);

        if (promoCode) {
            this.props.validateDiscountStart(promoCode, "", (discount) => {
                if (discount && discount.valid) {
                    localStorage.removeItem(DISCOUNT_CODE);
                } else if(discountCode) {
                    this.props.validateDiscountStart(discountCode);
                }

                this.props.history.push(`${getLangPrefix()}/`);
            });
        } else {
            this.props.history.push(`${getLangPrefix()}/`);
        }
    }

    render() {
        return null
    }
}

const mapStateToProps = (state: any): Props => {
    return {
        discount: getDiscount(state),
    }
};

const mapDispatchToProps = (dispatch): typeof OrderActionCreators => {
    return bindActionCreators(OrderActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ValidationDiscount);
