import {createReducer} from 'reduxsauce';
import {PaymentTypes} from './actions';
import { ADYEN_CLIENT_KEY, ADYEN_ENVIRONMENT } from '../../../utils/constants/variables';

export interface IPaymentState {
    amount: any,
    error: string,
    status: string,
    loading: boolean,
    encriptAmount: string,
    adyenSessionError: string,
    adyenSessionLoading: boolean,
    adyenConfiguration: any,
}

export const paymentR: IPaymentState = {
    amount: {},
    error: "",
    status: "",
    loading: false,
    encriptAmount:"",
    adyenSessionError: "",
    adyenSessionLoading: false,
    adyenConfiguration: {
        environment: ADYEN_ENVIRONMENT,
        clientKey: ADYEN_CLIENT_KEY,
        analytics: {
          enabled: true
        },
        paymentMethodsConfiguration: {
            card: {
              hasHolderName: true,
              holderNameRequired: true,
            },
          },
        showPayButton:true,
    }
};

export const amount = (state) => {
    return {...state, status: "", loading: true};
};

export const amountSuccess = (state, action) => {
    return {...state, amount: action.data,encriptAmount:action.data.encriptAmount, status: "", loading: false};
};

export const amountFailure = (state, action) => {
    return {...state, error: action.error, amount: {},encriptAmount:"", status: "failed", loading: false};
};

export const adyenSession = (state) => {
    return {...state, status: "", loading: true};
};

export const adyenSessionSuccess = (state, action) => {
    return {...state, adyenSession: action.data, adyenSessionLoading: false};
};

export const adyenSessionFailure = (state, action) => {
    return {...state, adyenSessionError: action.error, adyenSession: {}, adyenSessionLoading: false};
};

export const adyenConfigurationStart = (state, action) => {
    return {...state, adyenConfiguration: {...state.adyenConfiguration, ...action.data} };
};

export const handlers = {
    [PaymentTypes.AMOUNT_START]: amount,
    [PaymentTypes.AMOUNT_SUCCESS]: amountSuccess,
    [PaymentTypes.AMOUNT_FAILURE]: amountFailure,
    [PaymentTypes.ADYEN_SESSION_START]: adyenSession,
    [PaymentTypes.ADYEN_SESSION_SUCCESS]: adyenSessionSuccess,
    [PaymentTypes.ADYEN_SESSION_FAILURE]: adyenSessionFailure,
    [PaymentTypes.ADYEN_CONFIGURATION_START]: adyenConfigurationStart,
};

export default createReducer(paymentR, handlers);
