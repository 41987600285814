import React from 'react';
import i18next from "i18next";
import {Link} from "react-router-dom";
import {getDataBySectionAndWidget, getLangPrefix} from "./../../../../helpers/helpers";
import {BASE_API_GATEWAY} from "../../../../utils/constants/service";
import ImgBlob from "./imgBlob";

interface Props {
    data?: any,
    promotion?: string,
    photo?:any,
}
interface State {
    texts: any,
    showTexts: boolean
}

class Banner extends React.Component<Props,State> {

    state:State={
        texts:{},
        showTexts: false
    }

    componentWillMount()  {
        const {data, photo, promotion} = this.props;
        const {widget} = photo;
        const name = promotion === "Y" ? "-Promo" : "";
        const text = getDataBySectionAndWidget(1, widget, data, "TEXT", `Banner-Text${name}`).value
        const header = getDataBySectionAndWidget(1, widget+1, data, "TEXT", `Header${name}`).value
        const description = getDataBySectionAndWidget(1, widget +2, data, "TEXT", `Banner-Description${name}`).value
        this.setState(
              {texts: {text, header, description},
                    showTexts: !!(text || header || description)
                })
    }


    render() {
        const {texts, showTexts} = this.state;
        const {header, text, description} = texts
        const {photo} = this.props;


        return (
            <section className={`banner ${ showTexts ? "" : "singleBanner"}`}>
                {
                    <Link to={`${getLangPrefix()}${(photo && photo.menuHref) || ""}`}>
                        {
                            <ImgBlob
                                    src={`${BASE_API_GATEWAY}${photo && photo.value}`}
                                    alt={photo.altText || "banner"}/>
                        }

                        {
                            showTexts &&
                                <div className="banner-content">
                                    <h5 className="sub-title">{<p
                                        dangerouslySetInnerHTML={{__html: header}}/>}
                                    </h5>
                                    <div className="title">{<p
                                        dangerouslySetInnerHTML={{__html: text}}/>}
                                    </div>
                                    <p className="description">{<span
                                        dangerouslySetInnerHTML={{__html: description}}/>}
                                    </p>
                                    <p className="jalla-link-btn">
                                        {i18next.t('home.collection.discover')}
                                    </p>
                                 </div>

                        }
                    </Link>
                }
            </section>
        );
    }
}


export default Banner;
