import React from 'react';
import { connect } from "react-redux";
import './404.scss';
import i18next from "i18next";
import { Helmet } from 'react-helmet';
import { BRAND } from "../../utils/constants/variables";
import { BASE_API_GATEWAY } from '../../utils/constants/service';
import notFound from '../../assets/img/404.svg'
import { getNotFoundBanner } from '../../layouts/main/header/redux/selectors';

const Component404 = ({
    notFoundBanner
}) => {
    
    return (
        <>
            <Helmet>
                <title>{BRAND} 404</title>
                <meta name="description" content="404" data-react-helmet="true" />
            </Helmet>
            <div className="not-found-container" style={{
                backgroundImage: `url(${notFoundBanner ? `${BASE_API_GATEWAY}${notFoundBanner}` : notFound})`
            }}>
                <div className="not-found-code"></div>
                <div className="not-found-inner">
                    <div>
                        <p className="introuvable">{i18next.t('Error.1')}</p>
                        <p className="rien-sous">{i18next.t('Error.2')}</p>
                    </div>
                </div>
            </div>
        </>
    )

}

const mapStateToProps = (state: any): any => ({
    notFoundBanner: getNotFoundBanner(state),
});

export default connect(mapStateToProps, null)(Component404);