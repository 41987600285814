import { ConnectionsTypes, ConnectionsActionCreators } from "./actions";
import { takeLatest, put, call, select } from "redux-saga/effects";
import axios from 'axios';
import { EMAIL_REGISTRATION_CHECK_URL, STORES_BRAND_URL, PASSWORD_RESET_URL, PASSWORD_RESET_FINISH_URL } from "../../../utils/constants/service";
import { ALT_BRAND_ID, BRAND_ID } from "../../../utils/constants/variables";
import i18next from "i18next";
import { getBrandDetail } from "../../../layouts/main/header/redux/selectors";

export function* connection(params) {
    try {
        const { customerCode } = yield select(getBrandDetail);

        const headers = {
            headers: {
            }
        };
        const body = {
            "email": params.email,
            customerCode,
        };
        const data = yield call(() => {
            return axios.post(`${EMAIL_REGISTRATION_CHECK_URL}`, body, headers)
                .then(res => res.data)
        });

        yield put(ConnectionsActionCreators.connectionSuccess({ userstatus: data }));
    }catch(e){
        yield put(ConnectionsActionCreators.connectionFailure(e.message));
    }
}


export function* connectionPasswordReset(params) {
    try {
        const { customerCode } = yield select(getBrandDetail);
        const headers = { headers: {} };

        const role = yield call(() => {
            return axios.post(`${EMAIL_REGISTRATION_CHECK_URL}`, { "email": params.email, customerCode }, headers)
                .then(res => {
                    return res.data === "LEGACY_USER" ? true : false;
                })
        });

        const data = yield call(() => {
            return axios.post(`${PASSWORD_RESET_URL}${role}&brandId=${BRAND_ID}`, { "mail": params.email, customerCode }, headers)
                .then(res => res.data)
        });

        yield put(ConnectionsActionCreators.connectionPasswordResetSuccess({ userstatus: data }));
    } catch (e) {
        if (e.message.includes("403")) {
            yield put(ConnectionsActionCreators.connectionPasswordResetFailure(i18next.t("alertMessage.6")));
        } else {
            yield put(ConnectionsActionCreators.connectionPasswordResetFailure(i18next.t("alertMessage.5")));
        }
    }
}

export function* connectionPasswordResetFinish(params) {
    try {
        const headers = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const data = yield call(() => {
            return axios.post(`${PASSWORD_RESET_FINISH_URL}`, JSON.stringify(params.data), headers)
                .then(res => res.data)
        });

        yield put(ConnectionsActionCreators.connectionPasswordResetFinishSuccess(data));
    } catch (e) {
        yield put(ConnectionsActionCreators.connectionPasswordResetFinishFailure(e.message));
    }
}

export function* favoriteStore() {
    try {
        const headers = {};
        const country = localStorage.getItem("country");
        const caountryPayload = country ? `&country=${country}` : "";
        const data = yield call(() => {
            return axios.get(`${STORES_BRAND_URL}?brandId=${ALT_BRAND_ID || BRAND_ID}${caountryPayload}`, headers)
                .then(res => res.data)
        });
        yield put(ConnectionsActionCreators.favoriteStoreSuccess(data));
    } catch (e) {
        yield put(ConnectionsActionCreators.favoriteStoreFailure(e.message));
    }
}


export function* connectionSaga() {
    yield takeLatest(ConnectionsTypes.CONNECTION_START, connection);
    yield takeLatest(ConnectionsTypes.CONNECTION_PASSWORD_RESET_START, connectionPasswordReset);
    yield takeLatest(ConnectionsTypes.CONNECTION_PASSWORD_RESET_FINISH_START, connectionPasswordResetFinish);
    yield takeLatest(ConnectionsTypes.FAVORITE_STORE_START, favoriteStore);

}

export default connectionSaga;
