export const MENU_TYPE = "MENU";
export const PAGE_TYPE = "PAGE";
export const CATALOGUE_TYPE = "CATALOGUE";
export let LANGUAGE = localStorage.getItem("language") || 'it';
export const SUB_MENU_STYLES = "collezioni";
export const LANGUAGE_PREFIX = "/" + LANGUAGE;
export const STORAGE_BASKET_INDEX = "basket";
export const VALIDATE_DISCCOUNT = "validateDiscount";
export const DISCOUNT_CODE = "discountCode";
export const PROMOTION_DISCOUNT = 'promotionDiscount'
export const PRIVATE_DISCOUNT = 'privateDiscount'
export const STORAGE_BASKET_TOTALPRICE = "privateDiscount";
export const PRODUCT_MAX_QUANTITY = 10;
export const STORE_DELIVERY = process.env.REACT_APP_STORE_DELIVERY;
export const STORE_RETURN = process.env.REACT_APP_STORE_RETURN;
export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
export const CRM_INTEGRATION = process.env.REACT_APP_CRM_INTEGRATION;
export const BRAND_ID = 4;
export const ALT_BRAND_ID = 4;
export const REDEEM_POINTS = process.env.REACT_APP_REDEEM_POINTS;
export const DEFAULT_CURRENCY = "EUR";
export const EXPRESS_SHIPMENT = 12;
export const DISPATCH_TIME = 5;
export const BROWSER_KEY = "Wave2907$";
export const GTM_TAG = process.env.REACT_APP_GOOGLE_GTM;
export const BRAND = "Bassetti";
export const HIDE_DISCOUNT = process.env.REACT_APP_HIDE_DISCOUNT;
export const INSTAGRAM = false;
export const COUNTRY: "FR" | "DE" | "IT" = "IT";
export const PERSONALIZATION = process.env.REACT_APP_PERSONALIZATION;
export const EXPRESS_DELIVERY = process.env.REACT_APP_EXPRESS_DELIVERY;
export const FOREIGN_DELIVERY = process.env.REACT_APP_FOREIGN_DELIVERY;
export const GOOGLE_MAPS_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY;
export const FACEBOOK_PIXEL = process.env.REACT_APP_FACEBOOK_PIXEL;
export const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;
export const PAYPAL_ENV :any =  process.env.REACT_APP_PAYPAL_ENV;
export const ZENDESK_INTEGRATION = process.env.REACT_APP_ZENDESK_INTEGRATION;
export const REDONNER_INTEGRATION = "";
export const DEFAULT_LOCATION = "Milano"
export const APPOINTMENT_MAX_DAYS = 15;
export const APPOINTMENT_TIMES = ["10:00 - 10:30", "10:30 - 11:00", "11:00 - 11:30", "11:30 - 12:00", "14:00 - 14:30", "14:30 - 15:00", "15:00 - 15:30", "15:30 - 16:00", "16:00 - 16:30", "16:30 - 17:00", "17:00 - 17:30", "17:30 - 18:00"];
export const STORE_DEPOT = process.env.REACT_APP_STORE_DEPOT;
export const PRIVATE_SALE_POPUP = process.env.REACT_APP_PRIVATE_SALE_POPUP;
export const PROMOTION_RULES = process.env.REACT_APP_PROMOTION_RULES;
export const STOCK_LEFT = process.env.REACT_APP_STOCK_LEFT;
export const IPSTACK_KEY: any = process.env.REACT_APP_IPSTACK_KEY;
export const BASSETTI_CASA = "https://bassettihomeinnovation.it/";
export const AWIN_JS: any = process.env.REACT_APP_AWIN_JS;
export const AWIN_JS_LIB = "https://www.dwin1.com/99683.js";
export const COOKIE_TRACKING: 0 | 1 = 0;
export const WAVEUM_URL = 'https://www.waveum.com';
export const BASSETTI_DE = 'https://www.bassetti.de/';
export let PROMOTION_CLUSTER = 'E';
export let REACT_APP_CRM_INTEGRATION = 0
export let REACT_APP_ZENDESK_INTEGRATION = 1
export let DELIVERY_PRICE = 0; //instead of 150 = free shipping
export let SHIPMENT = 0;
export const ADYEN_CLIENT_KEY = process.env.REACT_APP_ADYEN_CLIENT_KEY;
export const ADYEN_ENVIRONMENT = process.env.REACT_APP_ADYEN_ENVIRONMENT;

export const updateVariables = (key, value) => {
    switch (key) {
        case "PROMOTION_CLUSTER":
            PROMOTION_CLUSTER = value;
            break;
        case "LANGUAGE":
            LANGUAGE = value;
            break;
    }
}

export function updateStoreVariables(details: any = {}) {
    try {
        const {
            crm,
            zendesk,
            shippingMinimum,
            shippingCost
        } = details;
        REACT_APP_CRM_INTEGRATION = crm;
        REACT_APP_ZENDESK_INTEGRATION = zendesk;
        DELIVERY_PRICE = shippingMinimum || 0;
        SHIPMENT = shippingCost || 0
    } catch (e) {
        console.log(e);
    }
}

export const PRODUCT_TYPES = {
    s: 'S',
    c: 'C',
    sc: 'SC',
};
export const PRODUCT_ITEM_TYPES = {
    GFC: 'GFC',
    EAN: 'EAN'
};

export const LOCAL_STORAGE_VARIABLES = {
    shippingCity: 'shippingCity'
}

export const ORDER_STATUS = {
    SHI: 'SHI',
    PAI: 'PAI',
    DEP: 'DEP',
    WIT: 'WIT',
    PEN: 'PEN',
    CHE: 'CHE',
    DIS: "DIS",
    STO: "STO",
    FAI: "FAI"
}

export const COUNTRY_LIST = ["FR", "BE", "MC", "AT", "DE", "CH", "IT"];
export enum AddressTypes {
    BILL = "BILL",
    SHIP = "SHIP"
}

export const initialMessage = {
    status: "",
    text: "",
    isVisible: false
}
